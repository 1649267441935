#navbar.color5 { background-color: $color5; }

#activite_diapo_1 {
	position: relative;
	height: 90vh;
}

#activite_diapo_1 .path_main_logo_triangle { fill: $color5; }

#activite_diapo_1 #main_logo #main_logo_texte {
	background: url(../images/texte_logo.png) no-repeat;
	background-size: contain;
}

.navbar-brand.color5 {
	background: url(../images/logo_navbar.png) no-repeat center center;
	background-size: contain;
}

#grosmot_activite {
	top: 25%;
	left: -10%;
	animation-name: grosmot_activite;
	animation-delay: 0s;
    animation-duration: $anim_grosmot_entetes_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes grosmot_activite {
    from { opacity: 0; left: -10%; }
    to { opacity: 1; left: 10%; }
}

#grosmot_activite2 {
	top: 35%;
	left: 60%;
	animation-name: grosmot_activite2;
	animation-delay: 0s;
    animation-duration: $anim_grosmot_entetes_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes grosmot_activite2 {
    from { opacity: 0; left: 50%; }
    to { opacity: 1; left: 20%; }
}

#grosmot_activite3 {
	top: 20%;
	left: -15%;
	animation-name: grosmot_activite3;
	animation-delay: 0s;
    animation-duration: $anim_grosmot_entetes_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes grosmot_activite3 {
    from { opacity: 0; left: -15%; }
    to { opacity: 1; left: 8%; }
}

#activite_diapo_1 .soustitre_milieu {
	position: relative;
	width: 100%;
	top: 55%;
	color: $color3;
	text-align: center;

	animation-name: apparition;
	animation-delay: 0s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#activite_diapo_1 .scroll_message {
	bottom: 130px;
}

// DIAPO GENERAL
.activite_diapo {
	
}

.activite_col_texte {
	color: $color3;
	padding: 6vw 8vw 12vw 8vw;
}

.activite_col_texte .titre {
	font-weight: 700;
}

.activite_col_texte .prix {
	font-size: 1.2em;
	font-weight: 700;
}

.activite_col_texte .prix span {
	font-family: "expo-serif-pro",serif;
	font-style: italic;
	font-weight: 500;
}

.activite_col_texte .soustitre {
	font-weight: 700;
	margin: 30px 0 30px 0;
}

.activite_col_texte .texte {
	
}

.activite_col_photo {
	z-index: 1;
	background-size: cover !important;
	min-height: 200px;
}

// ANIMATION RONDS
.anim_rond_container {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 50%;
	transform: translate(-50%, -50%);
	height: calc(5vw + 40px);
	width: calc(5vw + 40px);
}

.anim_rond_container .chiffre {
	position: absolute;
	color: $color3;
	font-size: 2em;
	font-weight: 700;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.svg_anim_rond_container {
	height: 100%;
	width: 100%;
}

.svg_anim_rond {
	height: 100%;
	width: 100%;

	@if ($symetrie == 1) {
	    transform: scaleY(-1); // Symétrie verticale, alias changement de sens
	}
}

.circle_fond {
	fill: none;
	stroke: white;
	stroke-width: 100% - ($rayon * 2);
	stroke-dasharray: $taille_jauge*$circonference (1-$taille_jauge)*$circonference;
	stroke-dashoffset: ($decalage/360) * $circonference;
}

.circle_devant {
	fill: none;
	stroke: $color5;
	stroke-width: 100% - ($rayon * 2);
	stroke-dasharray: 0% $circonference;
	stroke-dashoffset: ($decalage/360) * $circonference;
}

@keyframes jauge_1a { to { stroke-dasharray: $niveau_jauge_1*$taille_jauge*$circonference (1-($niveau_jauge_1*$taille_jauge))*$circonference; } }
@keyframes jauge_2a { to { stroke-dasharray: $niveau_jauge_2*$taille_jauge*$circonference (1-($niveau_jauge_2*$taille_jauge))*$circonference; } }
@keyframes jauge_3a { to { stroke-dasharray: $niveau_jauge_3*$taille_jauge*$circonference (1-($niveau_jauge_3*$taille_jauge))*$circonference; } }
@keyframes jauge_4a { to { stroke-dasharray: $niveau_jauge_4*$taille_jauge*$circonference (1-($niveau_jauge_4*$taille_jauge))*$circonference; } }

#svg_anim_rond_1.in-view #circle_anim_1 { 
	animation-name: jauge_1a;
	animation-delay: $delay_animation_jauge;
    animation-duration: $duree_animation_jauge;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#svg_anim_rond_2.in-view #circle_anim_2 { 
	animation-name: jauge_2a;
	animation-delay: $delay_animation_jauge;
    animation-duration: $duree_animation_jauge;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#svg_anim_rond_3.in-view #circle_anim_3 { 
	animation-name: jauge_3a;
	animation-delay: $delay_animation_jauge;
    animation-duration: $duree_animation_jauge;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#svg_anim_rond_4.in-view #circle_anim_4 { 
	animation-name: jauge_4a;
	animation-delay: $delay_animation_jauge;
    animation-duration: $duree_animation_jauge;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}


// DIAPOS
@keyframes from12to7col {
    from { width: 100%; }
    to { width: 58.33333%; }
}

@keyframes from0to5col_left {
    from { width: 0%; transform: translateX(-19vw); }
    to { width: 41.66667%; transform: translateX(0); }
}

@keyframes from0to5col_right {
    from { width: 0%; transform: translateX(19vw); }
    to { width: 41.66667%; transform: translateX(0); }
}

#activite_diapo_2 {
	position: relative;
}

#activite_diapo_2 #activite_diapo_2_photo {
	padding: 0;
	width: 0;
	transform: translateX(-19vw);
}

#activite_diapo_2 #activite_diapo_2_photo.in-view {
	animation-name: from0to5col_left;
	animation-delay: $activite_arrivee_img_delay;
    animation-duration: $activite_arrivee_img_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}


#activite_diapo_3 {
	position: relative;
}

#activite_diapo_3 #activite_diapo_3_photo {
	padding: 0;
	width: 0%;
	transform: translateX(19vw);
}

#activite_diapo_3 #activite_diapo_3_photo.in-view {
	animation-name: from0to5col_right;
	animation-delay: $activite_arrivee_img_delay;
    animation-duration: $activite_arrivee_img_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}


#activite_diapo_4 {
	position: relative;
}

#activite_diapo_4 #activite_diapo_4_photo {
	padding: 0;
	width: 0%;
	transform: translateX(-19vw);
}

#activite_diapo_4 #activite_diapo_4_photo.in-view {
	animation-name: from0to5col_left;
	animation-delay: $activite_arrivee_img_delay;
    animation-duration: $activite_arrivee_img_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}


#activite_diapo_5 {
	position: relative;
	width: 100%;
}

#activite_diapo_5 #activite_diapo_5_photo {
	padding: 0;
	width: 0%;
	transform: translateX(19vw);
}

#activite_diapo_5 #activite_diapo_5_photo.in-view {
	animation-name: from0to5col_right;
	animation-delay: $activite_arrivee_img_delay;
    animation-duration: $activite_arrivee_img_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}


#activite_diapo_6 {
	position: relative;
	height: $activite_diapo6_height;
	background: url(../images/illustration_activite_5.jpg) no-repeat center center;
	background-size: cover;
}

#activite_diapo_7 {
	position: relative;
	width: 100%;
/* 	padding-top: 5vw; */
}

#activite_diapo_7 #activite_diapo_7_photo {
	padding: 0;
	width: 0%;
	transform: translateX(-19vw);
}

#activite_diapo_7 #activite_diapo_7_photo.in-view {
	animation-name: from0to5col_left;
	animation-delay: $activite_arrivee_img_delay;
    animation-duration: $activite_arrivee_img_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

// Liens images
#activite_diapo_2_photo { background: url(../images/illustration_activite_1.jpg) no-repeat center center }
#activite_diapo_3_photo { background: url(../images/illustration_activite_2.jpg) no-repeat center center }
#activite_diapo_4_photo { background: url(../images/illustration_activite_3.jpg) no-repeat center center }
#activite_diapo_5_photo { background: url(../images/illustration_activite_4.jpg) no-repeat center center }
#activite_diapo_7_photo { background: url(../images/illustration_activite_7.jpg) no-repeat center center }




#petit_triangle_logo {
	fill: $color5;
}

#svg_anim_rond_5 .path_main_logo_rond {
	fill: white;
	opacity: 0;
}

#svg_anim_rond_5 .path_main_logo_triangle {
	fill: $color5;
	opacity: 0;
}

#svg_anim_rond_5.in-view .path_main_logo_rond {
	animation-name: apparition;
	animation-delay: $delay_animation_jauge;
    animation-duration: $duree_animation_jauge/2;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#svg_anim_rond_5.in-view .path_main_logo_triangle {
	animation-name: apparition;
	animation-delay: $delay_animation_jauge + $duree_animation_jauge/2;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}



